<template>
  <div class="switchParam">
    <!-- 参数 -->
    <Head :name="'智能开关'" :add="'/pc/ProSwitch'" :adds="'/pc/ProSwitchParam'" :color="false" />
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="paramTit">相关参数</div>
         <div class="paramImg">
          <img src="../../../assets/product/switchParam.png" alt class="img" />
          <div class="imgName">智能开关</div>
        </div>
        <div class="pas">
          <div class="pasLeft">
            <div class="li">
              <div class="pasName">产品型号:</div>
              <div class="pasCont">LS2032A</div>
            </div>
            <div class="li">
              <div class="pasName">产品尺寸:</div>
              <div class="pasCont">86×86×13mm</div>
            </div>
            <div class="li">
              <div class="pasName">额定电压:</div>
              <div class="pasCont">3V</div>
            </div>
            <div class="li">
              <div class="pasName">电池类型:</div>
              <div class="pasCont">CR2032</div>
            </div>
            <div class="li">
              <div class="pasName">工作温度:</div>
              <div class="pasCont">0°C-40°C</div>
            </div>
          </div>
          <div class="pasRight">
            <div class="li">
              <div class="pasName">工作温度:</div>
              <div class="pasCont">0%-95%RH，无冷凝</div>
            </div>
            <div class="li">
              <div class="pasName">通信协议:</div>
              <div class="pasCont">自定义LSSN2.0</div>
            </div>
            <div class="li">
              <div class="pasName">工作频段:</div>
              <div class="pasCont">433.0MHz~453.0MHz</div>
            </div>
            <div class="li">
              <div class="pasName">待机电流:</div>
              <div class="pasCont">＜2uA</div>
            </div>
            <div class="li">
              <div class="pasName">无线发射功率:</div>
              <div class="pasCont">＜10dBm</div>
            </div>
          </div>
        </div>
        <div class="text">
          <div
            class="tx"
          >智能开关是客控系统中控制光源的主要设备，提供了四个按键：两个独立的灯光开关和两个灯光场景开关，且均可定制，每个按键均有配备独立可调光的背光灯。开关通过自研的，具有低延迟、强抗干扰能力的协议与技术连接到主机。当连接主机时，可以通过主机独立控制每个灯的开关，以及控制更多灯光联动与场景。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding: 1.2rem 0px;
    .paramTit {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
      text-align: center;
    }
    .paramImg {
      margin-top: 0.65rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 3.02rem;
        height: 3.01rem;
      }
      .imgName {
        margin-top: 0.59rem;
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: 400;
        color: #333333;
      }
    }
    .pas {
      margin-top: 1rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        .li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.38rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            margin-right: 1.21rem;
          }
        }
      }
      .pasRight {
        .li {
          &:last-of-type {
            .pasName {
              margin-right: 0.72rem;
            }
          }
        }
      }
    }
    .text {
      margin-top: 0.43rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
       font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
